import Navbar from "./Navbar";
import NFTTile from "./NFTTile";
import MarketplaceJSON from "../Marketplace.json";
import axios from "axios";
import { useState } from "react";
import { GetIpfsUrlFromPinata, checkWalletConnected } from "../utils";

export default function Marketplace() {

const [data, updateData] = useState([]);
const [dataFetched, updateFetched] = useState(false);

// async function getAllNFTs() {
//     const ethers = require("ethers");
//     //After adding your Hardhat network to your metamask, this code will get providers and signers
//         // Check if window.ethereum is available
//     if (!checkWalletConnected()) {
//         console.error("Please install MetaMask or another Ethereum wallet!");
//         return; // Exit the function if no provider is available
//     }
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const signer = provider.getSigner();
//     //Pull the deployed contract instance
//     let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer)
//     //create an NFT Token
//     let transaction = await contract.getAllNFTs()

//     //Fetch all the details of every NFT from the contract and display
//     const items = await Promise.all(transaction.map(async i => {
//         var tokenURI = await contract.tokenURI(i.tokenId);
//         console.log("getting this tokenUri", tokenURI);
//         tokenURI = GetIpfsUrlFromPinata(tokenURI);
//         let meta = await axios.get(tokenURI);
//         meta = meta.data;

//         let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
//         let item = {
//             price,
//             tokenId: i.tokenId.toNumber(),
//             seller: i.seller,
//             owner: i.owner,
//             image: meta.image,
//             name: meta.name,
//             description: meta.description,
//         }
//         return item;
//     }))

//     updateFetched(true);
//     updateData(items);
// }

async function getAllNFTs() {
    const ethers = require("ethers");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider)

    try {
        const transaction = await contract.getAllNFTs();

        //Fetch all the details of every NFT from the contract and display
        const items = await Promise.all(transaction.map(async (i) => {
            var tokenURI = await contract.tokenURI(i.tokenId);
            tokenURI = GetIpfsUrlFromPinata(tokenURI);  // Ensure this helper function handles any errors
            const metaResponse = await axios.get(tokenURI);
            const meta = metaResponse.data;

            let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
            let item = {
                price,
                tokenId: i.tokenId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                image: meta.image,
                name: meta.name,
                description: meta.description,
            };
            return item;
        }));

        updateFetched(true);
        updateData(items);
    } catch (error) {
        console.error("Failed to fetch NFTs:", error);
        updateFetched(false);
        updateData([]);
    }
}

if(!dataFetched){
    // console.log("dataFetched: ", dataFetched)
    getAllNFTs();
}
return (
    <div>
        <Navbar></Navbar>
        <div className="flex flex-col place-items-center mt-20">
            <div className="md:text-xl font-bold text-white">
                Top NFTs
            </div>
            <div className="flex mt-5 justify-between flex-wrap max-w-screen-xl text-center">
                {data.map((value, index) => {
                    return <NFTTile data={value} key={index}></NFTTile>;
                })}
            </div>
        </div>            
    </div>
);

}