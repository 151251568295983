import logo from '../logo_3.png';
import fullLogo from '../full_logo.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ethers } from "ethers";


function Navbar() {

const [connected, toggleConnect] = useState(false);
const location = useLocation();
const [currAddress, updateAddress] = useState('0x');

async function getAddress() {
  if (!window.ethereum) {
    console.log("MetaMask is not installed.");
    updateAddress("0x");
    return; // Exit if no Ethereum provider is available
  }

  const provider = new ethers.providers.Web3Provider(window.ethereum);

  try {
    const accounts = await provider.listAccounts();
    if (accounts.length === 0) {
      console.log("No connected accounts.");
      updateAddress("0x");
      return; // Exit if no accounts are connected
    }

    const signer = provider.getSigner();
    const addr = await signer.getAddress();
    updateAddress(addr); // Update the UI with the address
  } catch (error) {
    console.error("Error fetching address:", error);
    updateAddress("0x");
    return; // Exit the function upon error
  }
}




function updateButton() {
  const ethereumButton = document.querySelector('.enableEthereumButton');
  ethereumButton.textContent = "Connected";
  ethereumButton.classList.remove("hover:bg-blue-70");
  ethereumButton.classList.remove("bg-blue-500");
  ethereumButton.classList.add("hover:bg-green-70");
  ethereumButton.classList.add("bg-green-500");
}

async function connectWebsite() {
  // Запрос на подключение к кошельку пользователя
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  if(accounts.length === 0) {
    // Пользователь не подключил кошелек или отказался от подключения
    console.log('Please connect to MetaMask.');
  } else {
    // Кошелек подключен, можно продолжать
    console.log('Account connected: ', accounts[0]);
    // Обновите состояние подключения здесь
    getAddress();
  }
}

useEffect(() => {
  // Early return if Ethereum object isn't found
  if (!window.ethereum) {
    console.log("Ethereum provider is not available.");
    return;
  }

  // Function to fetch the current address
  async function fetchCurrentAddress() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    try {
      const accounts = await provider.listAccounts();
      if (accounts.length > 0) {
        updateAddress(accounts[0]);
        toggleConnect(true); // Set connected state to true
      } else {
        updateAddress('0x');
        toggleConnect(false); // Set connected state to false
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      updateAddress('0x');
      toggleConnect(false);
    }
  }

  fetchCurrentAddress(); // Call this function on component mount

  // Subscribe to account change events
  const handleAccountsChanged = (accounts) => {
    if (accounts.length > 0) {
      updateAddress(accounts[0]);
      toggleConnect(true);
    } else {
      console.log("No accounts found after account change.");
      updateAddress("0x");
      toggleConnect(false);
    }
  };

  window.ethereum.on('accountsChanged', handleAccountsChanged);

  // Clean up event listener when component unmounts
  return () => window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
}, []);





return (
  <div className="bg-transparent text-white w-screen">
      <nav className="flex justify-between items-center py-3 px-5">
          <Link to="/" className="flex items-center">
              <img src={fullLogo} alt="" width={120} height={120} className="-mt-2 mr-2"/>
              <div className="font-bold text-xl">
                  NFT Marketplace
              </div>
          </Link>
          <div className="flex items-center"> {/* Flex container for the menu and the connect section */}
              <ul className="flex items-center gap-4 mr-4"> {/* Navigation menu */}
                  <li className={location.pathname === "/" ? 'border-b-2 p-2' : 'hover:border-b-2 p-2'}>
                      <Link to="/">Marketplace</Link>
                  </li>
                  <li className={location.pathname === "/sellNFT" ? 'border-b-2 p-2' : 'hover:border-b-2 p-2'}>
                      <Link to="/sellNFT">List My NFT</Link>
                  </li>
                  <li className={location.pathname === "/profile" ? 'border-b-2 p-2' : 'hover:border-b-2 p-2'}>
                      <Link to="/profile">Profile</Link>
                  </li>
              </ul>
              <div className="flex flex-col items-center"> {/* Flex column for the connect button and status text */}
                  <button className="enableEthereumButton bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm" onClick={connectWebsite}>
                      {connected ? "Connected" : "Connect Wallet"}
                  </button>
              </div>
          </div>
          
      </nav>
      <div className="text-sm mt-1 text-right mr-5">
          {currAddress !== "0x" ? "Connected to " + currAddress.substring(0, 15) + '...' : "Not Connected. Please login to view NFTs"}
      </div>
  </div>
);


  }

  export default Navbar;