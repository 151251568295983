import Navbar from "./Navbar";
import axie from "../tile.jpeg";
import { useLocation, useParams } from 'react-router-dom';
import MarketplaceJSON from "../Marketplace.json";
import axios from "axios";
import { useState } from "react";
import { GetIpfsUrlFromPinata } from "../utils";
// import { ethers } from "ethers";


export default function NFTPage (props) {

const [data, updateData] = useState({});
const [dataFetched, updateDataFetched] = useState(false);
const [message, updateMessage] = useState("");
const [currAddress, updateCurrAddress] = useState("0x");


// async function getNFTData(tokenId) {
//     const ethers = require("ethers");

//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const signer = provider.getSigner();
    
//     const addr = await signer();
//     //Pull the deployed contract instance
//     let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer)
//     //create an NFT Token
//     var tokenURI = await contract.tokenURI(tokenId);
//     const listedToken = await contract.getListedTokenForId(tokenId);
//     tokenURI = GetIpfsUrlFromPinata(tokenURI);
//     let meta = await axios.get(tokenURI);
//     meta = meta.data;
//     console.log(listedToken);

//     let item = {
//         price: meta.price,
//         tokenId: tokenId,
//         seller: listedToken.seller,
//         owner: listedToken.owner,
//         image: meta.image,
//         name: meta.name,
//         description: meta.description,
//     }
//     console.log(item);
//     updateData(item);
//     updateDataFetched(true);
//     console.log("address", addr)
//     updateCurrAddress(addr);
// }

async function getNFTData(tokenId) {
    const ethers = require("ethers");
    // Use a default provider if MetaMask is not installed or the user is not connected
    const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : new ethers.providers.JsonRpcProvider("https://eth-mainnet.alchemyapi.io/v2/YOUR_ALCHEMY_API_KEY");

    try {
        const contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider);
        const tokenURI = await contract.tokenURI(tokenId);
        const listedToken = await contract.getListedTokenForId(tokenId);
        const resolvedTokenURI = GetIpfsUrlFromPinata(tokenURI);
        const metaResponse = await axios.get(resolvedTokenURI);
        const meta = metaResponse.data;

        let item = {
            price: meta.price,
            tokenId: tokenId,
            seller: listedToken.seller,
            owner: listedToken.owner,
            image: meta.image,
            name: meta.name,
            description: meta.description,
        };

        updateData(item);
        updateDataFetched(true);
    } catch (error) {
        console.error("Failed to retrieve NFT data:", error);
        updateData(null);  
    }
}



// async function buyNFT(tokenId) {
//     try {
//         const ethers = require("ethers");
//         //After adding your Hardhat network to your metamask, this code will get providers and signers
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const signer = provider.getSigner();

//         //Pull the deployed contract instance
//         let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
//         const salePrice = ethers.utils.parseUnits(data.price, 'ether')
//         updateMessage("Buying the NFT... Please Wait (Upto 5 mins)")
//         //run the executeSale function
//         let transaction = await contract.executeSale(tokenId, {value:salePrice});
//         await transaction.wait();

//         alert('You successfully bought the NFT!');
//         updateMessage("");
//     }
//     catch(e) {
//         alert("Upload Error"+e)
//     }
// }

async function buyNFT(tokenId) {
    if (!window.ethereum) {
        alert("Please install MetaMask or another Ethereum wallet to proceed.");
        return;
    }

    try {
        const ethers = require("ethers");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []); // This line prompts the user to connect their wallet
        const signer = provider.getSigner();

        let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
        const salePrice = ethers.utils.parseUnits(data.price, 'ether');
        updateMessage("Buying the NFT... Please Wait (Up to 5 mins)");

        let transaction = await contract.executeSale(tokenId, { value: salePrice });
        await transaction.wait();

        alert('You successfully bought the NFT!');
        updateMessage("");
    } catch (error) {
        console.error("Error during purchase:", error);
        alert("Error during purchase: " + error.message);
        updateMessage("");
    }
}


    const params = useParams();
    const tokenId = params.tokenId;
    if(!dataFetched)
        getNFTData(tokenId);
    if(typeof data.image == "string")
        data.image = GetIpfsUrlFromPinata(data.image);

    return(
        <div style={{"minHeight":"100vh"}}>
            <Navbar></Navbar>
            <div className="flex ml-20 mt-20">
                <img src={data.image} alt="" className="w-2/5" />
                <div className="text-xl ml-20 space-y-8 text-white shadow-2xl rounded-lg border-2 p-5">
                    <div>
                        Name: {data.name}
                    </div>
                    <div >
                        Description: {data.description}
                    </div>
                    <div>
                        Price: <span className="">{data.price + " ETH"}</span>
                    </div>
                    <div>
                        Owner: <span className="text-sm">{data.owner}</span>
                    </div>
                    <div>
                        Seller: <span className="text-sm">{data.seller}</span>
                    </div>
                    <div>
                    { currAddress != data.owner && currAddress != data.seller ?
                        <button className="enableEthereumButton bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm" onClick={() => buyNFT(tokenId)}>Buy this NFT</button>
                        : <div className="text-emerald-700">You are the owner of this NFT</div>
                    }
                    
                    <div className="text-green text-center mt-3">{message}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}